body {
  background-color: #f4f4f5;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#root {
  height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.left-curve {
  height: 5vw;
  width: 10vw;
  bottom: 0;
  position: relative;
  top: 1px;
  overflow: hidden;
}

.left-curve::before {
  content: '';
  display: block;
  height: 100%;
  border-radius: 0 0 0 70%;
  box-shadow: 0 0 0 50vw #dcf0fe;
}

.right-curve {
  height: 5vw;
  width: 10vw;
  right: 0;
  position: absolute;
  top: 1px;
  overflow: hidden;
}

.right-curve::before {
  content: '';
  display: block;
  height: 100%;
  border-radius: 0 0 70% 0%;
  box-shadow: 0 0 0 50vw #dcf0fe;
}

.calendly-inline-widget {
  width: 100%;
  height: 100vh !important;
}

.react-tiny-popover-container {
  z-index: 30 !important;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  content: URL(https://freesvg.org/img/close_icon_black2.png);
  cursor: pointer;
  width: 10px;
  height: 10px;
}

.react-calendar .highlight {
  background-color: aquamarine;
}
.rpv-core__tooltip-body{
  display: none !important;
}